<template>
    <div>
        <main>
            <h1>Grey Light Galleri</h1>
            <p v-if="$route.params">{{ $route.params.reason }}</p>
            <p>Du har inte valt något galleri, därför hamnade du här.<br>
                Kontakta mäklaren för att få rätt adress. Medans du är här
                kan du spana in några av våra fotografers Instagram!
            </p>

            <div class="container">
                <a href="https://www.instagram.com/kronfoto">
                    <card data-image="https://www.instagram.com/p/B9TUhJ0F5C7/media">
                        <h1 slot="header">Adam</h1>
                        <p slot="content">@kronfoto</p>
                    </card>
                </a>

                <a href="https://www.instagram.com/fotografengustav/">
                    <card data-image="https://www.instagram.com/p/Bv_-RTXHhOu/media">
                        <h1 slot="header">Gustav</h1>
                        <p slot="content">@fotografengustav</p>
                    </card>
                </a>

                <a href="https://www.instagram.com/patrik_jakobsson/">
                    <card data-image="https://www.instagram.com/p/CB-LAC0nb4y/media">
                        <h1 slot="header">Patrik</h1>
                        <p slot="content">@patrik_jakobsson</p>
                    </card>
                </a>

                <a href="https://www.instagram.com/sulockimedia/">
                    <card data-image="https://www.instagram.com/p/B2gj_MyAB8h/media">
                        <h1 slot="header">Jan</h1>
                        <p slot="content">@sulockimedia</p>
                    </card>
                </a>
            </div>
        </main>
        <footer class="footer">
            <img src="@/assets/greylight.png" alt="Grey Light Galleri"><br>
            <small><a href="https://codepen.io/andymerskin/">Cards by Andy Merskin</a></small>
        </footer>
    </div>
</template>

<script>
import card from './Card'

export default {
    name: 'Main',
    components: { card },
    created() {
        this.$route.params.reason
    }
}
</script>

<style>
body {
  margin: 40px 0;
  font-family: "Raleway";
  font-size: 14px;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
}

.footer {
   position: fixed;
   left: 0;
   bottom: 10px;
   width: 100%;
   text-align: center;
}

.footer > img {
    opacity: 0.1;
}

.footer a {
   color: rgba(0, 0, 0, 0.55);
   text-decoration: none;
}

.title {
  font-family: "Raleway";
  font-size: 24px;
  font-weight: 700;
  color: #5D4037;
  text-align: center;
}

p {
  line-height: 1.5em;
}

h1 + p, p + p {
  margin-top: 10px;
}

.container {
    padding: 40px 80px;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    justify-content: center;
    margin-top: 5vh;
}

.card-wrap {
  margin: 10px;
  -webkit-transform: perspective(800px);
          transform: perspective(800px);
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  cursor: pointer;
}
.card-wrap:hover .card-info {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.card-wrap:hover .card-info p {
  opacity: 1;
}
.card-wrap:hover .card-info, .card-wrap:hover .card-info p {
  -webkit-transition: 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  transition: 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}
.card-wrap:hover .card-info:after {
  -webkit-transition: 5s cubic-bezier(0.23, 1, 0.32, 1);
  transition: 5s cubic-bezier(0.23, 1, 0.32, 1);
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.card-wrap:hover .card-bg {
  -webkit-transition: 0.6s cubic-bezier(0.23, 1, 0.32, 1), opacity 5s cubic-bezier(0.23, 1, 0.32, 1);
  transition: 0.6s cubic-bezier(0.23, 1, 0.32, 1), opacity 5s cubic-bezier(0.23, 1, 0.32, 1);
  opacity: 0.8;
}
.card-wrap:hover .card {
  -webkit-transition: 0.6s cubic-bezier(0.23, 1, 0.32, 1), box-shadow 2s cubic-bezier(0.23, 1, 0.32, 1);
  transition: 0.6s cubic-bezier(0.23, 1, 0.32, 1), box-shadow 2s cubic-bezier(0.23, 1, 0.32, 1);
  box-shadow: rgba(255, 255, 255, 0.2) 0 0 40px 5px, white 0 0 0 1px, rgba(0, 0, 0, 0.66) 0 30px 60px 0, inset #333 0 0 0 5px, inset white 0 0 0 6px;
}

.card {
  position: relative;
  -webkit-box-flex: 0;
          flex: 0 0 240px;
  width: 240px;
  height: 320px;
  background-color: #333;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.66) 0 30px 60px 0, inset #333 0 0 0 5px, inset rgba(255, 255, 255, 0.5) 0 0 0 6px;
  -webkit-transition: 1s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition: 1s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.card-bg {
  opacity: 0.7;
  position: absolute;
  top: -20px;
  left: -20px;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-transition: 1s cubic-bezier(0.445, 0.05, 0.55, 0.95), opacity 5s 1s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition: 1s cubic-bezier(0.445, 0.05, 0.55, 0.95), opacity 5s 1s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  pointer-events: none;
}

.card-info {
  padding: 20px;
  position: absolute;
  bottom: 0;
  color: #fff;
  -webkit-transform: translateY(40%);
          transform: translateY(40%);
  -webkit-transition: 0.6s 1.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: 0.6s 1.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.card-info p {
  opacity: 0;
  text-shadow: black 0 2px 3px;
  -webkit-transition: 0.6s 1.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: 0.6s 1.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.card-info * {
  position: relative;
  z-index: 1;
}
.card-info:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.6)));
  background-image: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.6) 100%);
  background-blend-mode: overlay;
  opacity: 0;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  -webkit-transition: 5s 1s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition: 5s 1s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.card-info h1 {
  font-family: "Playfair Display";
  font-size: 36px;
  font-weight: 700;
  text-shadow: rgba(0, 0, 0, 0.5) 0 10px 10px;
}

</style>