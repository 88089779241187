import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import Main from './components/Main'
import MediaSet from './components/MediaSet'

Vue.use(VueRouter)
Vue.config.productionTip = false

const routes = [
  { path: '*', component: Main }, 
  { path: '/:mediaset', component: MediaSet }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
